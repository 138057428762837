import React from 'react';
import './App.css';


const linksData = [
  { title: 'Advice-on-labelling-requirements_JUNE2022', url: '/Advice-on-labelling-requirements_JUNE2022_1.pdf' },
];

const LinkSection = ({ title, url, isFirst }) => (
  <div className={`link-section ${isFirst ? 'first' : ''}`}>
    <a href={url} target="_blank" rel="noopener noreferrer">{title}</a>
  </div>
);

const DocComponent = () => (
    <div className="container">
        {linksData.map((link, index) => (
            <LinkSection key={index} title={link.title} url={link.url} isFirst={index === 0}/>
        ))}
    </div>
);

export default DocComponent;