import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate  } from 'react-router-dom';
import DocComponent from "./doc";

function App() {
  return (
    <Router>
      <Routes>
        {/* 设置 doc 为默认页面 */}
        <Route exact path="/" element={<DocComponent />} />

        {/* 可以添加更多路由 */}
         <Route path="/doc/" element={<DocComponent />} />

        {/* 重定向未知路由到 doc */}
        <Route path="*" element={<Navigate to="/doc" />} />
      </Routes>
    </Router>
  );
}

export default App;
